import { axiosOnce as axios } from '@/axios_once'

export default {
  getCleanerApp() {
    return axios.get('/api/cleaner_signup')
  },
  postCleanerAppSection(sectionName, params) {
    return axios.post(`/api/cleaner_signup/${sectionName}`, params)
  },
  getApplicationImages(applicationId, context) {
    return axios.get(`/api/cleaner_application_images?application_id=${applicationId}&context=${context}`)
  },
  claimJob(reservationId, teamId, context) {
    return axios.post('/api/cleaning_jobs/claim', { reservation_id: reservationId, team_id: teamId, context: context })
  },
  setOnMyWayJobStatus(jobId, args) {
    return axios.post(`/api/cleaning_jobs/${jobId}/set_on_my_way`, args)
  },
  getEta(jobId) {
    return axios.get(`/api/cleaning_jobs/${jobId}/eta`)
  },
  getFirstUninspectedJob() {
    return axios.get(`/api/cleaning_jobs/first_uninspected`)
  },
  getReport(reportId) {
    return axios.get(`/api/inspection_reports/${reportId}`)
  },
  getServicesForInspectionReport() {
    return axios.get(`/api/inspection_reports/services`)
  },
  getReportImages(reportId) {
    return axios.get(`/api/inspection_reports/${reportId}/images`)
  },
  getReportNotes(reportId) {
    return axios.get(`/api/inspection_reports/${reportId}/notes`)
  },
  saveReport(reportId, params) {
    return axios.patch(`/api/inspection_reports/${reportId}`, params)
  },
  submitReport(reportId) {
    return axios.patch(`/api/inspection_reports/${reportId}/submit`)
  },
  removeReportImage(imageId) {
    return axios.delete(`/api/report_images/${imageId}`)
  },
  updateRange(range) {
    return axios.post('/api/profile/range', { range: range })
  },
  cleanerRateCleaningJob(jobId, rating) {
    return axios.post(`/api/cleaning_jobs/${jobId}/rate_client`, { rating: rating })
  },
  getStripeDashboardUrl() {
    return axios.get(`/api/profile/stripe_dashboard_url`)
  }
}
